<template>
    <div class="flex min-h-screen flex-col">
        <NavigationHeader
            v-if="routeSlug && currentCountry !== Country.NONE"
            :current-path="routeSlug" />
        <main class="-mt-7 flex-1 lg:-mt-9">
            <slot> </slot>
        </main>
        <ButtonScrollToTop></ButtonScrollToTop>
        <NavigationFooter v-if="routeSlug" :current-path="routeSlug" />
    </div>
</template>

<script setup lang="ts">
    import { Country } from '~/types/enums/Country';

    const route = useRoute();
    const routeSlug = ref<string | string[]>('');
    const currentCountry = useCurrentCountry();
    const { setLocale } = useI18n();

    if (route.params.slug) routeSlug.value = route.params.slug;
    else if (route.path != '/') routeSlug.value = route.path;

    /** Set global current country */
    currentCountry.value = getCountryCodeFromUrl(routeSlug.value);

    /** Set i18n locale */
    if (
        currentCountry.value === Country.DE ||
        currentCountry.value === Country.AT ||
        currentCountry.value === Country.CH
    ) {
        setLocale('de');
    } else {
        setLocale(currentCountry.value);
    }
</script>
