<script setup lang="ts">
    import { type HTMLAttributes, computed } from 'vue';
    import {
        DropdownMenuContent,
        type DropdownMenuContentEmits,
        type DropdownMenuContentProps,
        DropdownMenuPortal,
        useForwardPropsEmits,
    } from 'radix-vue';
    import { cn } from '@/utils/ui';

    const props = withDefaults(
        defineProps<DropdownMenuContentProps & { class?: HTMLAttributes['class'] }>(),
        {
            sideOffset: 4,
            class: '',
        },
    );
    const emits = defineEmits<DropdownMenuContentEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
    <DropdownMenuPortal>
        <DropdownMenuContent
            v-bind="forwarded"
            :class="
                cn(
                    'z-50 flex min-w-6 flex-col gap-2 overflow-hidden rounded-md border border-neutral-100 bg-shades-white p-3 text-blue-900 shadow-xl data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    props.class,
                )
            ">
            <slot />
        </DropdownMenuContent>
    </DropdownMenuPortal>
</template>
