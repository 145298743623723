<script setup lang="ts">
    import { type HTMLAttributes, computed } from 'vue';
    import { DropdownMenuItem, type DropdownMenuItemProps, useForwardProps } from 'radix-vue';
    import { cn } from '@/utils/ui';

    const props = defineProps<
        DropdownMenuItemProps & { class?: HTMLAttributes['class']; inset?: boolean }
    >();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
    <DropdownMenuItem
        v-bind="forwardedProps"
        :class="
            cn(
                'relative flex cursor-default select-none items-center outline-none focus:text-blue-400 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                inset && 'pl-8',
                props.class,
            )
        ">
        <slot />
    </DropdownMenuItem>
</template>
