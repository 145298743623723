<template>
    <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.57305 12.4801L5.6543 16.923H26.0879L29.8054 12.4801H7.57305Z" fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.57305 12.4801L5.6543 16.923H26.0879L29.8054 12.4801H7.57305Z"
            stroke="#02BFE4"
            stroke-width="0.313761"
            stroke-miterlimit="2" />
        <path d="M7.57305 12.4801L5.6543 16.923H26.0879L29.8054 12.4801H7.57305Z" fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.57305 12.4801L5.6543 16.923H26.0879L29.8054 12.4801H7.57305Z"
            stroke="#02BFE4"
            stroke-width="0.699688"
            stroke-miterlimit="2" />
        <path
            d="M0.849609 28.0477H16.7785L20.5247 23.5708H2.78391L0.849609 28.0477Z"
            fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.849609 28.0477H16.7785L20.5247 23.5708H2.78391L0.849609 28.0477Z"
            stroke="#02BFE4"
            stroke-width="0.313761"
            stroke-miterlimit="2" />
        <path
            d="M0.849609 28.0477H16.7785L20.5247 23.5708H2.78391L0.849609 28.0477Z"
            fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.849609 28.0477H16.7785L20.5247 23.5708H2.78391L0.849609 28.0477Z"
            stroke="#02BFE4"
            stroke-width="0.699688"
            stroke-miterlimit="2" />
        <path d="M12.3914 1.32214L10.459 5.79845H35.396L39.1421 1.32214H12.3914Z" fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3914 1.32214L10.459 5.79845H35.396L39.1421 1.32214H12.3914Z"
            stroke="#02BFE4"
            stroke-width="0.313761"
            stroke-miterlimit="2" />
        <path d="M12.3914 1.32214L10.459 5.79845H35.396L39.1421 1.32214H12.3914Z" fill="#02BFE4" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3914 1.32214L10.459 5.79845H35.396L39.1421 1.32214H12.3914Z"
            stroke="#02BFE4"
            stroke-width="0.699688"
            stroke-miterlimit="2" />
    </svg>
</template>
