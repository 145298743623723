<template>
    <a
        v-if="alternate"
        class="flex items-center gap-2 hover:text-blue-400"
        :href="getUrlFromStoryblokAlternate(alternate)">
        <component
            :is="atFlag"
            v-if="getCountryCodeFromUrl(alternate.full_slug) === Country.AT"
            class="h-5 w-6"></component>
        <component
            :is="deFlag"
            v-else-if="getCountryCodeFromUrl(alternate.full_slug) === Country.DE"
            class="h-5 w-6"></component>
        <component
            :is="chFlag"
            v-else-if="getCountryCodeFromUrl(alternate.full_slug) === Country.CH"
            class="h-5 w-6"></component>
        <component
            :is="frFlag"
            v-else-if="getCountryCodeFromUrl(alternate.full_slug) === Country.FR"
            class="h-5 w-6"></component>
        <span class="font-bold">{{
            getCountryCodeFromUrl(alternate.full_slug).toUpperCase()
        }}</span>
    </a>

    <div v-else class="flex items-center gap-2">
        <component :is="atFlag" v-if="currentCountry === Country.AT" class="h-5 w-6"></component>
        <component
            :is="deFlag"
            v-else-if="currentCountry === Country.DE"
            class="h-5 w-6"></component>
        <component
            :is="chFlag"
            v-else-if="currentCountry === Country.CH"
            class="h-5 w-6"></component>
        <component
            :is="frFlag"
            v-else-if="currentCountry === Country.FR"
            class="h-5 w-6"></component>
        <span class="font-bold" :class="desktop ? '' : 'text-blue-400'">{{
            currentCountry.toUpperCase()
        }}</span>
    </div>
</template>

<script setup lang="ts">
    import type { AlternateStoryblok } from '~/types/storyblok-alternate';
    import { Country } from '~/types/enums/Country';

    const currentCountry = useCurrentCountry();

    // Cant be dynamically chosen with a function due to the nature of resolveComponent
    const atFlag = resolveComponent('icons/at-flag');
    const deFlag = resolveComponent('icons/de-flag');
    const chFlag = resolveComponent('icons/ch-flag');
    const frFlag = resolveComponent('icons/fr-flag');

    const props = defineProps<{
        alternate?: AlternateStoryblok;
        desktop?: boolean;
        dropdownAvailable?: boolean;
    }>();
</script>
