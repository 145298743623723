import type { AlternateStoryblok } from '~/types/storyblok-alternate';

export const getUrlFromStoryblokAlternate = (alternate: AlternateStoryblok): string => {
    const config = useRuntimeConfig();
    const regExp = new RegExp('/b2b|/b2c', 'ig');

    let modifiedLink = alternate.full_slug.replace(regExp, '');

    // Add trailing slash
    if (!modifiedLink.endsWith('/')) {
        modifiedLink = modifiedLink + '/';
    }

    return `${config.public.hostName}/${modifiedLink}`;
};
