<template>
    <Popover>
        <PopoverTrigger
            v-bind="$attrs"
            class="group flex items-center gap-1 font-bold hover:text-blue-400 focus:text-blue-400 focus-visible:outline-none data-[state=open]:text-blue-400">
            <slot name="trigger"> </slot>
            <IconsChevronDown
                class="h-6 w-6 stroke-blue-400 transition-transform duration-300 group-data-[state=open]:rotate-180" />
        </PopoverTrigger>
        <PopoverContent
            style="width: var(--radix-popper-available-width)"
            class="mt-6 max-h-[calc(100dvh-80px)] overflow-y-auto rounded-b-3xl rounded-t-none border-t-1 border-neutral-200 bg-shades-white p-0 shadow-2xl">
            <slot></slot>
        </PopoverContent>
    </Popover>
</template>
