<template>
    <div v-if="footerMenu" v-editable="footerMenu" class="bg-blue-900 text-blue-50">
        <SectionContainer class="flex flex-col gap-7 py-7 sm:gap-8 sm:!py-8">
            <div class="flex flex-col justify-between gap-y-7 md:flex-row md:gap-0">
                <div class="flex flex-col gap-6 sm:gap-7">
                    <div class="flex flex-col gap-2">
                        <IconsLogosInstadriveFull class="w-13 fill-shades-white" />
                        <span>{{ $t('tagline_emissions_with_hashtag') }}</span>
                    </div>

                    <div class="flex flex-col gap-3">
                        <div class="flex flex-col gap-2">
                            <StoryblokComponent
                                v-for="phone_number in footerMenu.phone_numbers"
                                :key="phone_number._uid"
                                :blok="phone_number" />
                        </div>

                        <div class="flex gap-4">
                            <IconsMail class="h-6 w-6 stroke-blue-100" /><span
                                class="text-lg font-bold"
                                >{{ $t('sales_email') }}</span
                            >
                        </div>
                    </div>

                    <div class="flex gap-6">
                        <StoryblokComponent
                            v-for="social in footerMenu.socials"
                            :key="social._uid"
                            class="h-6 w-6"
                            :blok="social" />
                    </div>
                </div>

                <div class="flex flex-col gap-6 sm:gap-7">
                    <div>
                        <p class="mb-4 text-2xl font-bold">{{ footerMenu.download_title }}</p>
                        <div class="flex flex-col gap-3">
                            <StoryblokComponent
                                v-for="asset in footerMenu.assets"
                                :key="asset._uid"
                                :blok="asset" />
                        </div>
                    </div>

                    <div class="flex flex-col gap-4">
                        <p>{{ $t('newsletter.title') }}</p>
                        <NewsletterSignUpField></NewsletterSignUpField>
                    </div>
                </div>
            </div>

            <ul class="flex flex-wrap justify-center gap-x-6 gap-y-4 md:justify-between md:gap-0">
                <li v-for="page in footerMenu.links" :key="page._uid">
                    <NuxtLink :to="getUrlFromStoryblokLink(page.link)" class="hover:text-blue-400">
                        {{ page.link_title }}
                    </NuxtLink>
                </li>
            </ul>
            <p class="text-xs">{{ footerMenu.copyright_text }}</p>
        </SectionContainer>
    </div>
</template>

<script setup lang="ts">
    import { StoryblokUrl } from '~/types/enums/StoryblokUrl';
    import type { FooterNavStoryblok } from '~/types/component-types-sb';

    const props = defineProps<{ currentPath: string | string[] }>();

    const config = useRuntimeConfig();
    const preUrl = 'cdn/stories';

    const apiRoute = ref(preUrl + buildStoryblokUrl(props.currentPath, StoryblokUrl.FOOTER));

    const storyblokApi = useStoryblokApi();
    const { data } = await storyblokApi.get(apiRoute.value, {
        version: config.public.env === 'production' ? 'published' : 'draft',
        resolve_links: 'url',
    });

    const footerMenu: globalThis.Ref<FooterNavStoryblok> = ref(data.story.content);
</script>
