<template>
    <div class="flex md:flex-col md:justify-between">
        <NuxtLink :to="link" class="flex items-center justify-center">
            <NuxtImg
                loading="lazy"
                :src="props.imageSource"
                :alt="props.imageAlt"
                format="webp"
                class="aspect-[1.7/1] w-11 max-w-12 object-contain md:w-full"
                :provider="props.imageProvider" />
        </NuxtLink>
        <div class="space-y-2 whitespace-nowrap md:text-center">
            <p class="font-bold">
                {{ props.name }}
            </p>
            <div class="flex flex-col justify-center md:items-center xl:flex-row xl:gap-5">
                <p v-if="props.price" class="flex items-baseline gap-2">
                    {{ $t('car.before_price') }}
                    <span class="font-bold">
                        {{ getLocalizedPrice(props.price) }}
                    </span>
                    {{
                        config.public.siteMode === 'B2B'
                            ? $t('car.after_price_b2b')
                            : $t('car.after_price_b2c')
                    }}
                </p>
                <NuxtLink :to="props.link" class="underline">{{ $t('car.configure') }}</NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps<{
        imageProvider: 'cloudimage' | 'storyblok';
        imageSource: string;
        imageAlt?: string;
        name: string;
        price?: number;
        link: string;
    }>();

    const config = useRuntimeConfig();
</script>
