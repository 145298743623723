<template>
    <Transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="scale-95 translate-y-5 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 translate-y-5 opacity-0">
        <button
            v-show="showScrollToTopButton"
            class="fixed bottom-3 right-3 z-40 flex gap-2 rounded-full bg-blue-400 px-4 py-3 text-shades-white shadow-md sm:bottom-5 sm:right-5"
            @click="y = 0">
            <IconsArrowUp class="h-6 w-6 stroke-shades-white"></IconsArrowUp>
            {{ $t('button_scroll_to_top') }}
        </button>
    </Transition>
</template>

<script lang="ts" setup>
    const { y } = useWindowScroll();

    const showScrollToTopButton = computed(() => {
        return y.value > 1000;
    });
</script>
