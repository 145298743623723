<script setup lang="ts">
    import { type HTMLAttributes, computed } from 'vue';
    import {
        PopoverContent,
        type PopoverContentEmits,
        type PopoverContentProps,
        PopoverPortal,
        useForwardPropsEmits,
    } from 'radix-vue';
    import { cn } from '@/utils/ui';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(
        defineProps<PopoverContentProps & { class?: HTMLAttributes['class'] }>(),
        {
            align: 'center',
            sideOffset: 4,
            class: '',
        },
    );
    const emits = defineEmits<PopoverContentEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
    <PopoverPortal>
        <PopoverContent
            v-bind="{ ...forwarded, ...$attrs }"
            :class="
                cn(
                    ' z-50 rounded-md border border-neutral-100 bg-shades-white p-4 text-blue-900 shadow-xl outline-none data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[side=bottom]:slide-in-from-top-2 data-[side=bottom]:slide-out-to-top-2 data-[side=left]:slide-in-from-right-2 data-[side=left]:slide-out-to-right-2 data-[side=right]:slide-in-from-left-2 data-[side=right]:slide-out-to-left-2 data-[side=top]:slide-in-from-bottom-2 data-[side=top]:slide-out-to-bottom-2',
                    props.class,
                )
            ">
            <slot />
        </PopoverContent>
    </PopoverPortal>
</template>
