<script setup lang="ts">
    import { DropdownMenuTrigger, type DropdownMenuTriggerProps, useForwardProps } from 'radix-vue';

    const props = defineProps<DropdownMenuTriggerProps>();

    const forwardedProps = useForwardProps(props);
</script>

<template>
    <DropdownMenuTrigger class="group flex outline-none" v-bind="forwardedProps">
        <slot />
        <IconsChevronDown
            class="relative h-6 w-6 stroke-blue-400 transition duration-300 disabled:stroke-neutral-400 group-data-[state=open]:rotate-180"
            aria-hidden="true" />
    </DropdownMenuTrigger>
</template>
