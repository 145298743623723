<template>
    <HeadlessSwitch
        v-model="switchedToBusiness"
        class="relative inline-flex rounded-lg bg-neutral-200 text-neutral-500"
        @update:model-value="changeSite">
        <div class="flex">
            <div class="flex w-1/2 items-center justify-center px-6 py-3">
                <span
                    class="relative z-20 transition"
                    :class="{ 'text-shades-white': switchedToBusiness }"
                    ><slot name="private-text"></slot
                ></span>
            </div>
            <div class="flex w-1/2 items-center justify-center px-4 py-2">
                <span
                    :class="{ 'text-shades-white': !switchedToBusiness }"
                    class="relative z-20 transition">
                    <slot name="business-text"> </slot
                ></span>
            </div>
        </div>
        <div
            :class="[switchedToBusiness ? 'translate-x-0' : 'translate-x-full']"
            class="absolute z-10 h-full w-1/2 rounded-lg bg-blue-400 transition"></div>
    </HeadlessSwitch>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    const config = useRuntimeConfig();
    const currentCountry = useCurrentCountry();

    function changeSite() {
        if (config.public.siteMode === 'B2C') {
            window.location.href = `https://firmenwagen.insta-drive.com/${currentCountry.value}`;
        } else {
            window.location.href = `https://insta-drive.com/${currentCountry.value}`;
        }
    }

    const switchedToBusiness = ref(config.public.siteMode === 'B2C');
</script>
